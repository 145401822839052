module.exports = [
  {
    id: "helvetica",
    name: "Helvetica",
  },
  {
    id: "courier",
    name: "Courier",
  },
  {
    id: "times",
    name: "Times New Romans",
  },
  {
    id: "aealarabiya,sans",
    name: "Aealarabiya",
  },
  {
    id: "aefurat,serif",
    name: "Aefurat",
  },
  {
    id: "dejavusans,sans",
    name: "Deja Vu (sans)",
  },
  {
    id: "dejavusansb,sans",
    name: "Deja Vu (sans) Bold",
  },
  {
    id: "dejavusansi,sans",
    name: "Deja Vu (sans) Italic",
  },
  {
    id: "dejavusansbi,sans",
    name: "Deja Vu (sans) Bold Italic",
  },
  {
    id: "dejavusanscondensed,sans",
    name: "Deja Vu (sans) Condensed",
  },
  {
    id: "dejavusanscondensedb,sans",
    name: "Deja Vu (sans) Condensed Bold",
  },
  {
    id: "dejavusanscondensedi,sans",
    name: "Deja Vu (sans) Condensed Italic",
  },
  {
    id: "dejavusanscondensedbi,sans",
    name: "Deja Vu (sans) Condensed Bold Italic",
  },
  {
    id: "dejavusansextralight,sans",
    name: "Deja Vu (sans) Extra Light",
  },
  {
    id: "dejavusansmono,sans",
    name: "Deja Vu (sans) Mono",
  },
  {
    id: "dejavusansmonob,sans",
    name: "Deja Vu (sans) Mono Bold",
  },
  {
    id: "dejavusansmonoi,sans",
    name: "Deja Vu (sans) Mono Italic",
  },
  {
    id: "dejavusansmonobi",
    name: "Deja Vu (sans) Mono Bold Italic",
  },
  {
    id: "dejavuserif,serif",
    name: "Deja Vu (serif)",
  },
  {
    id: "dejavuserifb,serif",
    name: "Deja Vu (serif) Bold",
  },
  {
    id: "dejavuserifi,serif",
    name: "Deja Vu (serif) Italic",
  },
  {
    id: "dejavuserifbi,serif",
    name: "Deja Vu (serif) Bold Italic",
  },
  {
    id: "dejavuserifcondensed,serif",
    name: "Deja Vu (serif) Condensed",
  },
  {
    id: "dejavuserifcondensedb,serif",
    name: "Deja Vu (serif) Condensed Bold",
  },
  {
    id: "dejavuserifcondensedi,serif",
    name: "Deja Vu (serif) Condensed Italic",
  },
  {
    id: "dejavuserifcondensedbi,serif",
    name: "Deja Vu (serif) Condensed Bold Italic",
  },
  {
    id: "freemono",
    name: "Free Mono",
  },
  {
    id: "freemonob,freemono",
    name: "Free Mono Bold",
  },
  {
    id: "freemonoi,freemono",
    name: "Free Mono Italic",
  },
  {
    id: "freemonobi,freemono",
    name: "Free Mono Bold Italic",
  },
  {
    id: "freesans",
    name: "Free Sans",
  },
  {
    id: "freesansb,freesans,sans",
    name: "Free Sans Bold",
  },
  {
    id: "freesansi,freesans,sans",
    name: "Free Sans Italic",
  },
  {
    id: "freesansbi,freesans,sans",
    name: "Free Sans Bold Italic",
  },
  {
    id: "freeserif",
    name: "Free Serif",
  },
  {
    id: "freeserifb,freeserif,serif",
    name: "Free Serif Bold",
  },
  {
    id: "freeserifi,freeserif,serif",
    name: "Free Serif Italic",
  },
  {
    id: "freeserifbi,freeserif,serif",
    name: "Free Serif Bold Italic",
  },
  {
    id: "hysmyeongjostdmedium,sans",
    name: "HYSMyungJo Medium",
  },
  {
    id: "kozgopromedium,sans",
    name: "KozGoPro Medium",
  },
  {
    id: "kozminproregular,sans",
    name: "KozGoPro Regular",
  },
  {
    id: "msungstdlight,sans",
    name: "MSungStd Light",
  },
  {
    id: "pdfacourier,courier",
    name: "PDFA Courier",
  },
  {
    id: "pdfacourierb,courier",
    name: "PDFA Courier Bold",
},
{
  id: "pdfacourieri,courier",
  name: "PDFA Courier Italic",
},
  {
    id: "pdfacourierbi,courier",
    name: "PDFA Courier Bold Italic",
  },
  {
    id: "pdfahelvetica,helvetica",
    name: "PDFA Helvetica",
  },
  {
    id: "pdfahelveticab,helvetica",
    name: "PDFA Helvetica Bold",
  },
  {
    id: "pdfahelveticabi,helvetica",
    name: "PDFA Helvetica Bold Italic",
  },
  {
    id: "pdfahelveticai,helvetica",
    name: "PDFA Helvetica Italic",
  },
  {
    id: "symbol",
    name: "Symbol",
  },
  {
    id: "pdfasymbol,symbol",
    name: "PDFA Symbol",
  },
  {
    id: "pdfatimes,times,serif",
    name: "PDFA Times New Romans",
  },
  {
    id: "pdfatimesb,times,serif",
    name: "PDFA Times New Romans Bold",
  },
  {
    id: "pdfatimesbi,times,serif",
    name: "PDFA Times New Romans Bold Italic",
  },
  {
    id: "pdfatimesi,times,serif",
    name: "PDFA Times New Romans Italic",
  },
  {
    id: "stsongstdlight,sans",
    name: "STSongStd Light",
  },
  {
    id: "zapfdingbats",
    name: "ZAPF Ding Bats",
  },
  {
    id: "pdfazapfdingbats",
    name: "PDFA ZAPF Ding Bats",
  },
];
