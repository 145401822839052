<template>
  <div id="app">
    <SortableList v-if="type === `list`" :sidebar_tabs="sidebar_tabs" />
    <SortableListByGroup
      v-else-if="type === `list-by-group`"
      :sidebar_tabs="sidebar_tabs"
    />
    <SortableGridCoverDesign
      :cols="cols"
      v-else-if="type === `grid-cover-design`"
    />
    <SortableGridInternalDesign
      :cols="cols"
      v-else-if="type === `grid-internal-design`"
    />
    <SortableGridSpecial
      :cols="cols"
      :designs="designs"
      :sidebar_tabs="sidebar_tabs"
      v-else-if="type === `grid-special`"
    />
    <SortableGrid
      v-else-if="type === `grid`"
      :cols="cols"
      :special="special"
      :designs="designs"
      :sidebar_tabs="sidebar_tabs"
    />
    <PromptContainer
      :module="module"
      :page_id="page_id"
      :sidebar_tabs="sidebar_tabs"
      v-else-if="type === `prompt`"
    />
    <UserPromptsContainer
      :module="module"
      :page_id="page_id"
      :sidebar_tabs="sidebar_tabs"
      v-else-if="type === `user-prompts`"
    />
    <DefaultFontContainer v-else-if="type === `default-font`" />

    <DownloadButtonContainer v-else-if="type === `download-button`" />

    <MyWritingsContainer v-else-if="type === `my-writings`" />

    <CoverPageSettingsContainer v-else-if="type === `cover-page-settings`" />

    <LifeOutlineContainer v-else-if="type === `life-outline`" />

    <MyIntentionsContainer v-else-if="type === `my-intention`" />

    <TheFactsContainer v-else-if="type === `the-facts`" />

    <MyFirstsContainer v-else-if="type === `my-firsts`" />

    <FavoriteThingsContainer v-else-if="type === `favorite-things`" />

    <AttributesContainer v-else-if="type === `attributes`" />

    <HealthHistoryContainer v-else-if="type === `health-history`" />
  </div>
</template>

<script>
export default {
  components: {
    SortableList: () => import("./components/SortableList.vue"),
    SortableGrid: () => import("./components/SortableGrid.vue"),
    PromptContainer: () => import("./components/PromptContainer.vue"),
    MyWritingsContainer: () => import("./components/MyWritingsContainer.vue"),
    SortableListByGroup: () => import("./components/SortableListByGroup.vue"),
    SortableGridSpecial: () => import("./components/SortableGridSpecial.vue"),
    SortableGridCoverDesign: () => import("./components/SortableGridCoverDesign.vue"),
    SortableGridInternalDesign: () => import("./components/SortableGridInternalDesign.vue"),
    UserPromptsContainer: () => import("./components/UserPromptsContainer.vue"),
    DefaultFontContainer: () => import("./components/DefaultFontContainer.vue"),
    DownloadButtonContainer: () => import("./components/DownloadButtonContainer.vue"),
    CoverPageSettingsContainer: () => import("./components/CoverPageSettingsContainer.vue"),
    LifeOutlineContainer: () => import("./components/pfmj/LifeOutlineContainer.vue"),
    MyIntentionsContainer: () => import("./components/pfmj/MyIntentionsContainer.vue"),
    TheFactsContainer: () => import("./components/pfmj/TheFactsContainer.vue"),
    MyFirstsContainer: () => import("./components/pfmj/MyFirstsContainer.vue"),
    FavoriteThingsContainer: () => import("./components/pfmj/FavoriteThingsContainer.vue"),
    AttributesContainer: () => import("./components/pfmj/AttributesContainer.vue"),
    HealthHistoryContainer: () => import("./components/pfmj/HealthHistoryContainer.vue"),
  },
  data() {
    return {
      type: this.$root.type,
      cols: this.$root.cols,
      special: this.$root.special,
      designs: this.$root.designs,
      module: this.$root.module,
      page_id: this.$root.page_id,
      sidebar_tabs: this.$root.sidebar_tabs,
      user_prompts: this.$root.user_prompts,
      template_name: this.$root.template_name,
      sidebar_default_view: this.$root.sidebar_default_view,
    };
  },
  created() {
    this.$store.dispatch("setGlobalSettings", ["designs", this.$root.designs]);
  },
};
</script>
