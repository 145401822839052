const designs = [];

[
  {
    name: "bark",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/bark.png",
  },
  {
    name: "blue_line_border",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/Blue+line+border.png",
  },
  {
    name: "blue_watercolor_background",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/blue+watercolor+background.png",
  },
  {
    name: "brown_leaves",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/brown+leaves.png",
  },
  {
    name: "gold_border",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/gold+border.png",
  },
  {
    name: "hawk",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/hawk.png",
  },
  {
    name: "linen",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/linen.png",
  },
  {
    name: "linen_with_tree_",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/linen+with+tree+.png",
  },
  {
    name: "painted_blue_line",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/painted+blue+line.png",
  },
  {
    name: "rose_border",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/rose+border.png",
  },
  {
    name: "two_leaves",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/two+leaves.png",
  },
  {
    name: "tree_on_blue",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/tree+on+blue.png",
  },
  {
    name: "flowers_on_linen",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/flowers+on+linen.png",
  },
  {
    name: "dedication_copy",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/dedication+copy.png",
  },
  {
    name: "blue_with_gold_border",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/blue+with+gold+border.png",
  },
  {
    name: "blue_on_blush",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/blue+on+blush.png",
  },
  {
    name: "big_flowers_on_blue",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Internal+Pages/big+flowers+on+blue.png",
  },
].map(({ name, download }) => {
  designs.push({
    id: name,
    series: "v2",
    number: name,
    image_src: `/assets/images/legacy-journal/pages/v2/${name}-thumb.png`,
    image_src2: `/assets/images/legacy-journal/pages/v2/${name}.webp`,
    download_link: download,
    groups: ["page", "chapter", "default", "writing", "content", "picture"],
    pictures: 0,
    show_title: false,
  });
});

[
  {
    name: "option_2_cascading_flowers",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+2+cascading+flowers.png",
    show_title: false,
  },
  {
    name: "option_3_trees",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+3+trees.png",
    show_title: false,
  },
  {
    name: "option_4_cherryblossoms_with_words",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+4+cherryblossoms+with+words.png",
    show_title: false,
  },
  {
    name: "option_5_brown_flower",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+5+brown+flower.png",
    show_title: false,
  },
  {
    name: "option_6_heart",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+6+heart.png",
    show_title: false,
  },
  {
    name: "option_7_bouquet",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+7+bouquet.png",
    show_title: false,
  },
  {
    name: "option8__blue_sash",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option8++blue+sash.png",
    show_title: false,
  },
  {
    name: "option_9_mountains",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+9+mountains.png",
    show_title: false,
  },
  {
    name: "option_10_blue_flower",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+10+blue+flower.png",
    show_title: false,
  },
  {
    name: "option_11_cascading_flowers_no_words",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+11+cascading+flowers+no+words.png",
    show_title: true,
  },
  {
    name: "option_12_cherry_blossoms_no_words",
    download: "https://discoveryjournals.s3.us-west-1.amazonaws.com/Covers/Option+12+cherry+blossoms+no+words.png",
    show_title: true,
  },
].map(({ name, download, show_title }) => {
  designs.push({
    id: name,
    series: "v2",
    number: name,
    image_src: `/assets/images/legacy-journal/covers/v2/${name}-thumb.png`,
    image_src2: `/assets/images/legacy-journal/covers/v2/${name}.webp`,
    download_link: download,
    groups: ["cover"],
    pictures: 0,
    show_title: show_title,
  });
});

module.exports = [
  {
    id: "_",
    series: "",
    number: "",
    image_src: "",
    image_src2: "",
    groups: [
      "page",
      "chapter",
      "default",
      "cover",
      "writing",
      "content",
      "picture",
    ],
    pictures: 0,
  },
  ...designs,
];
