import Vue from "vue";
import App from "./App.vue";
import VTooltip from "v-tooltip";
import VueToast from "vue-toast-notification";
import store from "./store.js";
import "vue-toast-notification/dist/theme-default.css";
import VModal from "vue-js-modal";

import "./assets/css/global.css";

Vue.use(VTooltip);
Vue.use(VueToast);
Vue.use(VModal, {
  dynamicDefaults: {
    height: "600px",
    width: "80%",
    styles: "overflow:unset;",
  },
});

Vue.config.productionTip = false;

const type = document
  .getElementById("legacy-journal-app-container")
  ?.getAttribute("data-type");

const cols =
  document
    .getElementById("legacy-journal-app-container")
    ?.getAttribute("data-cols") ?? 6;

const special =
    document
      .getElementById("legacy-journal-app-container")
      ?.getAttribute("data-special") ?? 0;

const designs =
      document
        .getElementById("legacy-journal-app-container")
        ?.getAttribute("data-designs") ?? "0";

const module =
        document
          .getElementById("legacy-journal-app-container")
          ?.getAttribute("data-module") ?? "1";

const page_id =
        document
          .getElementById("legacy-journal-app-container")
          ?.getAttribute("data-page_id") ?? "1";

const sidebar_tabs =
          document
            .getElementById("legacy-journal-app-container")
            ?.getAttribute("data-sidebar_tabs") ?? "1";

const user_prompts =
            document
              .getElementById("legacy-journal-app-container")
              ?.getAttribute("data-user_prompts") ?? "0";

const template_name =
            document
              .getElementById("legacy-journal-app-container")
              ?.getAttribute("data-template_name") ?? "";

const sidebar_default_view =
            document
              .getElementById("legacy-journal-app-container")
              ?.getAttribute("data-sidebar_default_view") ?? null;

new Vue({
  render: (h) => h(App),
  store,
  data: {
    type,
    cols: parseInt(cols),
    special,
    designs,
    module,
    page_id,
    sidebar_tabs,
    user_prompts,
    template_name,
    sidebar_default_view,
  },
}).$mount("#fjtl-app-container");
