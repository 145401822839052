import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import page_designs from "./data/designs";
import fonts from "./data/fonts";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    page_designs: page_designs,
    fonts: fonts,
    defaults: {},
    current_writing_index: 0,
    design_modal: false,
    writing_modal: false,
    pages: [],
    trashed_pages: [],
    last_action: null,
    pictures: [],
    global_settings: {},
    user_prompts: [],
    module_prompts: [],
    prompt_writings: [],
    writings_groups: [],
    writings_group_pages: [],
    cover_design_data: [],
    internal_design_data: '',
    loading: false,
    webspeech: null,
  },
  getters: {
    getPageDesigns: (state) => state.page_designs,
    getFonts: (state) => state.fonts,
    getWritings: (state) => state.pages.filter(p => p.page_type==='writing'),
    getLegacyJournal: (state) => state.pages.filter(p => p.page_type==='writing'),
    getDesignModal: (state) => state.design_modal,
    getWritingModal: (state) => state.writing_modal,
    getPages: (state) => state.pages,
    getTrashedPages: (state) => state.trashed_pages,
    getPictures: (state) => state.pictures,
    getDefaults: (state) => state.defaults,
    getGlobalSettings: (state) => state.global_settings,
    getUserPrompts: (state) => state.user_prompts,
    getModulePrompts: (state) => state.module_prompts,
    getPromptWritings: (state) => state.prompt_writings,
    getWritingGroups: (state) => state.writings_groups,
    getWritingGroupPages: (state) => state.writings_group_pages,
    getCoverDesignData: (state) => state.cover_design_data,
    getInternalDesignData: (state) => state.internal_design_data,
    getLoading: (state) => state.loading,
    getWebspeech: (state) => state.webspeech,
  },
  mutations: {
    SET_CURRENT_WRITING_INDEX(state, value) {
      state.current_writing_index = value;
    },
    SET_LEGACY_JOURNAL(state, items) {
      items.map((item) => {
        if( item ) {
          const isObject = typeof item?.pages_settings === "object";
          if (!isObject && (item?.pages_settings) && item?.pages_settings !== "") {
            item.pages_settings = JSON.parse(item?.pages_settings);
          }
          item.design = state.page_designs.find((d) => d.id === item.page_design);
          return item;
        }
      });
      state.pages = items;
    },
    SET_TRASHED_LEGACY_JOURNAL(state, items) {
      state.trashed_pages = items;
    },
    SET_DESIGN_MODAL(state, value) {
      state.design_modal = value;
    },
    SET_WRITING_MODAL(state, value) {
      state.writing_modal = value;
    },
    SET_PAGES(state, items) {
      items.map((item) => {
        const isObject = typeof item.pages_settings === "object";
        if (!isObject && item.pages_settings !== "") {
          item.pages_settings = JSON.parse(item.pages_settings);
        }
        item.design = state.page_designs.find((d) => d.id === item.page_design);
      });
      state.pages = items;
    },
    INSERT_PAGES(state, items) {
      items.map((item) => {
        const exists = state.pages.find(p => p.ID === item.ID);
        if( exists ) return;
        const isObject = typeof item.pages_settings === "object";
        if (!isObject && item.pages_settings !== "") {
          item.pages_settings = JSON.parse(item.pages_settings);
        }
        item.design = state.page_designs.find((d) => d.id === item.page_design);
        state.pages.push(item);
      });
    },
    INSERT_TRASHED_PAGES(state, items) {
      items.map((item) => {
        
        const exists = state.pages.find(p => p.ID === item.ID);
          if( exists ) {
            state.pages
            .filter(p => p.ID === item.ID)
            .map(p => p.post_status = 'publish');
            return;
          }
        const isObject = typeof item.pages_settings === "object";
        if (!isObject && item.pages_settings !== "") {
          item.pages_settings = JSON.parse(item.pages_settings);
        }
        item.design = state.page_designs.find((d) => d.id === item.page_design);
        state.pages.push(item);
      });
    },
    UPDATE_PAGES(state, [items]) {
      items.map((item, index) => {
        const current_item = state.pages.find((fItem) => {
          return fItem.ID === item.ID;
        });
        current_item.page_position = index;
      });
    },

    UPDATE_PAGE_POSITION(state, [items]) {
      state.last_action = 'UPDATE_PAGE_POSITION';
      items.map((item) => {
        state.pages.find((fItem) => {
          return fItem.ID === item.ID;
        });
      });
    },
    ADD_PAGE(state, item) {
      if( typeof item.pages_settings === 'undefined' ) {
        item.pages_settings = "";
      }
      const isObject = typeof item.pages_settings === "object";
      if (!isObject && item.pages_settings !== "") {
        item.pages_settings = JSON.parse(item?.pages_settings);
      }
      item.design = state.page_designs.find((d) => d.id === item.page_design);
      state.pages.push(item);
    },
    SET_PICTURES(state, items) {
      state.pictures = items;
    },
    ADD_PICTURE(state, item) {
      state.pictures.unshift(item);
    },
    SET_DEFAULTS(state, items) {
      state.defaults = {...state.defaults,...items};
    },
    UPDATE_DEFAULTS(state, [key, value]) {
      state.defaults[key] = value;
    },
    SET_USER_PROMPTS(state, { items }) {
      state.user_prompts = items;
    },
    SET_MODULE_PROMPTS(state, { items }) {
      state.module_prompts = items;
    },
    ADD_USER_PROMPTS(state, item) {
      state.user_prompts.unshift(item);
    },
    ADD_PROMPT_WRITINGS(state, { items }) {
      items.map(item => {
        state.prompt_writings.push(item);
      })
    },
    SET_WRITINGS_GROUPS(state, { items }) {
      state.writings_groups = items;
    },
    ADD_WRITINGS_GROUP(state, item) {
      state.writings_groups.push(item);
    },
    SET_WRITINGS_GROUP_PAGES(state, { items }) {
      state.writings_group_pages = items;
    },
    ADD_WRITINGS_GROUP_PAGE(state, item) {
      state.writings_group_pages.push(item);
    },
    SET_COVER_DESIGN_DATA(state, {id, design }) {
      state.cover_design_data = [id , design];
    },
    SET_INTERNAL_DESIGN_DATA(state, design) {
      state.internal_design_data = design;
    },
    SET_LOADING(state, value) {
      state.loading = value ? true : false;
    },
    SET_WEBSPEECH(state, value) {
      state.webspeech = value;
    },
  },
  actions: {
    addNewPage({ commit }, [item, page_type, callback]) {
      var params = new FormData();
      params.append("action", "call_add_legacy_journal_page");
      params.append("writing_id", item.ID);
      params.append("page_type", page_type);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("ADD_PAGE", data);
          callback(data);
        });
    },

    

    updatePage({ state }, [page_id, post_title, post_content, callback]) {
      state.last_action = "updatePage";
      var params = new FormData();
      params.append("action", "call_update_legacy_journal_page");
      params.append("page_id", page_id);
      params.append("post_title", post_title);
      params.append("post_content", post_content);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    updateStatus({ state }, [ID, status, callback]) {
      state.last_action = "updateStatus";
      var params = new FormData();
      params.append("action", "call_update_legacy_journal_status");
      params.append("page_id", ID);
      params.append("status", status ? "publish" : "draft");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data: { message } }) => {
          callback({
            message: message,
          });
        });
    },
    removePage({ state, commit }, [ID, callback]) {
      var params = new FormData();
      params.append("action", "call_remove_legacy_journal_page");
      params.append("page_id", ID);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data: { message } }) => {
          commit(
            "SET_PAGES",
            state.pages.filter((page) => page.ID !== ID)
          );
          callback({
            message: message,
          });
        });
    },
    loadPages({ state, commit }, [ID,callback]) {
      const exists = state.pages.filter(
        (p) => parseInt(p.parent_id) === parseInt(ID)
      );

      if (exists.length > 0) {
        callback(exists);
        return;
      }

      var params = new FormData();
      params.append("action", "call_get_legacy_journal_pages");
      params.append("writing_id", ID);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("INSERT_PAGES", data);
          callback(data);
        });
    },

    getPageById({ state, commit }, [ID,callback]) {
      const exists = state.pages.filter(
        (i) => parseInt(i.writing_id) === parseInt(ID)
      );

      if (exists.length > 0) {
        callback(exists);
        return;
      }

      var params = new FormData();
      params.append("action", "call_get_legacy_journal_page_by_id");
      params.append("writing_id", ID);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ success, data }) => {
          if( success ) {
            commit("ADD_PAGE", data);
          }
          callback(data);
        });
    },

    loadAllLegacyJournal({ commit }, callback) {
      var params = new FormData();
      params.append("action", "call_get_all_legacy_journal");
      
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_LEGACY_JOURNAL", data?.items);
          callback();
        });
    },

    loadTrashedLegacyJournal({ commit }, callback) {
      var params = new FormData();
      params.append("action", "call_get_trashed_legacy_journal");
      
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_TRASHED_LEGACY_JOURNAL", data?.items);
          callback();
        });
    },

    loadLegacyJournal({ commit }, [only_writings, callback]) {
      var params = new FormData();
      params.append("action", "call_get_legacy_journal");
      params.append("only_writings", only_writings);
      
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_LEGACY_JOURNAL", data);
          callback(data);
        });
    },

    loadPictures({ commit },callback) {
      var params = new FormData();
      params.append("action", "call_get_pictures");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_PICTURES", data);
          callback(data);
        });
    },

    deletePicture({ state, commit }, [ID, callback]) {
      var params = new FormData();
      params.append("action", "call_delete_picture");
      params.append("picture_id", ID);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data: { message } }) => {
          commit(
            "SET_PICTURES",
            state.pictures.filter((pic) => pic.ID !== ID)
          );
          callback({
            message: message,
          });
        });
    },

    updateLegacyJournalPosition({ getters }, callback) {
      let payload = [];

      getters.getLegacyJournal.map((item, index) => {
        //if (parseInt(item.position) !== index) {
          payload.push({
            writing_id: item.ID,
            position: index,
          });
          //item.position = index;
        //}
      });

      const params = new FormData();
      params.append("action", "call_update_legacy_journal_positions2");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((response) => callback(response));
    },

    updateLegacyJournalPosition2({ state }, [items,callback]) {
      state.last_action = "updateLegacyJournalPosition2"
      let payload = [];

      items.map((item, index) => {
          payload.push({
            writing_id: item.ID,
            position: index,
          });
      });

      const params = new FormData();
      params.append("action", "call_update_legacy_journal_positions2");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((response) => callback(response));
    },

    updateMainPosition({ state }, [items, callback]) {
      state.last_action = "updateMainPosition";
      let payload = [];

      items.map((item, index) => {
        if (parseInt(item.original_position) !== index) {
          payload.push({
            ID: item.ID,
            position: index,
          });
          item.original_position = index;
        }
      });

      const params = new FormData();
      params.append("action", "call_update_legacy_journal_main_positions");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((data) => callback(data));
    },

    updatePagesPosition({ state }, [items, callback]) {
      state.last_action = "updatePagesPosition";
      let payload = [];

      items.map((item, index) => {
        if (parseInt(item.page_position_orig) !== index) {
          payload.push({
            ID: item.ID,
            position: index,
          });
          item.page_position_orig = index;
        }
      });

      const params = new FormData();
      params.append("action", "call_update_legacy_journal_pages_positions");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((data) => callback(data));
    },

    updatePageMeta({ state, commit }, [page_id, meta_key, meta_value, callback]) {
      
      state.last_action = "update_page_meta";
      var params = new FormData();
      params.append("action", "call_update_legacy_journal_meta");
      params.append("page_id", page_id);
      params.append("meta_key", meta_key);
      params.append("meta_value", meta_value);

      commit('SET_LOADING', true);
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
          commit('SET_LOADING', false);
        });
    },

    updatePostTitle({ state }, [page_id, title, callback]) {
      state.last_action = "call_update_legacy_journal_title";
      var params = new FormData();
      params.append("action", "call_update_legacy_journal_title");
      params.append("page_id", page_id);
      params.append("title", title);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    getUserMeta({ state, commit }, [meta_key, callback]) {
      state.last_action = "getUserMeta";
      var params = new FormData();
      params.append("action", "call_get_user_meta");
      params.append("meta_key", meta_key);

      commit('SET_LOADING', true);
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
          commit('SET_LOADING', false);
        });
    },

    updateUserMeta({ state, commit }, [meta_key, meta_value, callback]) {
      state.last_action = "updateUserMeta";
      var params = new FormData();
      params.append("action", "call_update_user_meta");
      params.append("meta_key", meta_key);
      params.append("meta_value", meta_value);

      commit('SET_LOADING', true);
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
          commit('SET_LOADING', false);
        });
    },
    loadDefaults({ commit }, [keys,callback]) {
      var params = new FormData();
      params.append("action", "call_get_legacy_journal_defaults");
      params.append("keys", keys);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_DEFAULTS", data);
          callback(data);
        });
    },
    addToLegacyJournal({ state }, [writing_id, status, callback]) {
      state.last_action = "addToLegacyJournal";
      var params = new FormData();
      params.append("action", "call_add_to_legacy_journal");
      params.append("writing_id", writing_id);
      params.append("status", status);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },
    activateSpecialPage({ state }, [page_type, callback]) {
      state.last_action = "activateSpecialPage";
      var params = new FormData();
      params.append("action", "call_activate_special_page");
      params.append("page_type", page_type);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },
    setGlobalSettings({state}, [key,value]) {
      switch(key) {
        case 'designs':
          Object.assign(state.global_settings, {designs:value});
          break;
      }
    },

    uploadFilestack({ commit }, [results, callback]) {
      
      var params = new FormData();
      params.append("action", "call_filestack_upload");
      params.append("results", results);
      
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          const { items } = data;
          items?.map(item => {
              commit("ADD_PICTURE", item);
          });
          callback(data);
        });
    },

    loadUserPrompts({ commit }, [module, callback]) {
      var params = new FormData();
      params.append("action", "call_get_user_prompts");
      params.append("module", module);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_USER_PROMPTS", data);
          callback(data);
        });
    },

    loadModulePrompts({ commit }, [module,page_id,callback]) {
      var params = new FormData();
      params.append("action", "call_get_module_prompts");
      params.append("module", module);
      params.append("page_id", page_id);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_MODULE_PROMPTS", data);
          callback(data);
        });
    },

    loadPromptWriting({ commit }, [prompt, callback]) {
      var params = new FormData();
      params.append("action", "call_get_prompt_writing");
      params.append("prompt", prompt);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("ADD_PROMPT_WRITINGS", data);
          callback(data);
        });
    },

    loadDeeperQuestions({ state }, [prompt, callback]) {
      state.last_action  = "loadDeeperQuestions";
      var params = new FormData();
      params.append("action", "call_get_deeper_questions");
      params.append("prompt", prompt);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    addUserPrompt({ commit }, [module, prompt, callback]) {
      var params = new FormData();
      params.append("action", "call_add_user_prompt2");
      params.append("module", module);
      params.append("prompt", prompt);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback( data );
          commit("ADD_USER_PROMPTS", data.item);
        });
    },

    savePromptWriting({ state }, [prompt_id, post_title, post_content, module, callback]) {
      state.last_action = "savePromptWriting";
      var params = new FormData();
      params.append("action", "call_save_user_writing");
      params.append("prompt_id", prompt_id);
      params.append("post_title", post_title);
      params.append("post_content", post_content);
      params.append("module", module);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    updatePromptWriting({ state }, [page_id, post_title, post_content, callback]) {
      state.last_action = "updatePromptWriting";
      var params = new FormData();
      params.append("action", "call_update_legacy_journal_page");
      params.append("page_id", page_id);
      params.append("post_title", post_title);
      params.append("post_content", post_content);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    loadWritingsGroups({ commit }, callback) {
      var params = new FormData();
      params.append("action", "call_get_writings_groups");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit('SET_WRITINGS_GROUPS', data);
          callback(data);
        });
    },

    addWritingsGroup({ state, commit }, [id,value,callback]) {
      
      commit('ADD_WRITINGS_GROUP', {
        id : id,
        value: value,
      });

      var params = new FormData();
      params.append("action", "call_save_writings_groups");
      params.append("groups", JSON.stringify(state.writings_groups));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    deleteWritingsGroup({ state }, [index,callback]) {
      
      state.writings_groups.splice(index,1);

      var params = new FormData();
      params.append("action", "call_save_writings_groups");
      params.append("groups", JSON.stringify(state.writings_groups));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },

    trashWriting({ state }, [id,callback]) {
      state.last_action = "trashWriting";
      var params = new FormData();
      params.append("action", "call_trash_writing");
      params.append("writing_id", id);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },

    restoreWriting({ state }, [id,callback]) {
      state.last_action = "restoreWriting";
      var params = new FormData();
      params.append("action", "call_restore_writing");
      params.append("writing_id", id);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },

    eraseWriting({ state }, [id,callback]) {
      state.last_action = "eraseWriting";
      var params = new FormData();
      params.append("action", "call_erase_writing");
      params.append("writing_id", id);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },


    changeItemWritingsGroup({ state }, [item_id,group_id,group_value,callback]) {
      state.last_action = "changeItemWritingsGroup";
      
      var params = new FormData();
      params.append("action", "call_update_writing_group");
      params.append("writing_id", item_id);
      params.append("group_id", group_id);
      params.append("group_label", group_value);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },

    sendViaEmail({ state }, [item_id,email_address,callback]) {
      state.last_action = "sendViaEmail";
      
      var params = new FormData();
      params.append("action", "call_send_via_email");
      params.append("writing_id", item_id);
      params.append("email_address", email_address);
      
      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });

    },

    loadWritingsGroupPages({ commit }, callback) {
      var params = new FormData();
      params.append("action", "call_get_writings_group_pages");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit('SET_WRITINGS_GROUP_PAGES', data);
          callback(data);
        });
    },

    addWritingsGroupPage({ commit }, [group_name,menu_order,callback]) {
      
      var params = new FormData();
      params.append("action", "call_add_writings_groups_page");
      params.append("group_name", group_name);
      params.append("menu_order", menu_order);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit('ADD_WRITINGS_GROUP_PAGE', data.item);
          callback(data);
        });
    },

    deleteWritingsGroupPage({ state, commit }, [group_id,callback]) {
      
      var params = new FormData();
      params.append("action", "call_delete_writings_group_page");
      params.append("group_id", group_id);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          const groups = state.writings_group_pages.filter(p => p.ID !== group_id);
          commit('SET_WRITINGS_GROUP_PAGES', {items: groups});
          callback(data);
        });
    },

    changeItemWritingsGroupPage({ state }, [item_id,group_id,group_value,callback]) {
      state.last_action = "changeItemWritingsGroupPage";
      
      var params = new FormData();
      params.append("action", "call_update_writings_group_page");
      params.append("writing_id", item_id);
      params.append("group_id", group_id);
      params.append("group_label", group_value);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          
          callback(data);
        });

    },

    updateWritingsGroupPagePosition({ getters }, callback) {
      let payload = [];

      getters.getWritingGroupPages.map((item, index) => {
          payload.push({
            writing_id: item.ID,
            position: index,
          });
      });

      const params = new FormData();
      params.append("action", "call_update_writings_group_page_position");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((response) => callback(response));
    },

    updateLegacyJournalPositionByGroup({ state }, [items, callback]) {
      state.last_action = "updateLegacyJournalPositionByGroup";
      let payload = [];

      items.map((item, index) => {
          payload.push({
            writing_id: item.ID,
            position: index,
          });
      });

      const params = new FormData();
      params.append("action", "call_update_writings_group_page_position");
      params.append("items", JSON.stringify(payload, null, 0));

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then((response) => callback(response));
    },

    getCoverDesign({ state, commit }, callback) {
      state.last_action = "getCoverDesign";
      var params = new FormData();
      params.append("action", "call_get_cover_design");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_COVER_DESIGN_DATA", {
            id: data.page_id,
            design: data.design
          });
          callback(data);
        });
    },

    getInternalDesign({ state, commit }, callback) {
      state.last_action = "getInternalDesign";
      var params = new FormData();
      params.append("action", "call_get_internal_design");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit("SET_INTERNAL_DESIGN_DATA", data.value);
          callback(data);
        });
    },

    saveDefaultFonts({ state, commit }, [fontFamily, fontSize, lineHeight, callback]) {
      state.last_action = "saveDefaultFonts";

      var params = new FormData();
      params.append("action", "call_save_default_fonts");
      params.append("default_font_family", fontFamily);
      params.append("default_font_size", fontSize);
      params.append("default_line_height", lineHeight);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit('UPDATE_DEFAULTS', ['default_font_family', data.default_font_family]);
          commit('UPDATE_DEFAULTS', ['default_font_size', data.default_font_size]);
          commit('UPDATE_DEFAULTS', ['default_line_height', data.default_line_height]);
          callback(data);
        });
    },

    saveCoverPageSettings({ state, commit }, [payload, callback]) {
      state.last_action = "saveDefaultFonts";

      const { 
        currentFont, 
        currentSize, 
        title, 
        title_size, 
        title_color, 
        subtitle, 
        subtitle_size, 
        subtitle_color, 
        author, 
        author_size,
        author_color,
      } = payload;
      var params = new FormData();
      params.append("action", "call_save_cover_page_settings");
      params.append("default_font_family", currentFont);
      params.append("default_font_size", currentSize);
      params.append("cover_page_title", title);
      params.append("cover_page_title_size", title_size);
      params.append("cover_page_title_color", title_color);
      params.append("cover_page_subtitle", subtitle);
      params.append("cover_page_subtitle_size", subtitle_size);
      params.append("cover_page_subtitle_color", subtitle_color);
      params.append("cover_page_author", author);
      params.append("cover_page_author_size", author_size);
      params.append("cover_page_author_color", author_color);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          commit('UPDATE_DEFAULTS', ['default_font_family', data.default_font_family]);
          commit('UPDATE_DEFAULTS', ['default_font_size', data.default_font_size]);
          commit('UPDATE_DEFAULTS', ['cover_page_title', data.cover_page_title]);
          commit('UPDATE_DEFAULTS', ['cover_page_title_size', data.cover_page_title_size]);
          commit('UPDATE_DEFAULTS', ['cover_page_title_color', data.cover_page_title_color]);
          commit('UPDATE_DEFAULTS', ['cover_page_subtitle', data.cover_page_subtitle]);
          commit('UPDATE_DEFAULTS', ['cover_page_subtitle_size', data.cover_page_subtitle_size]);
          commit('UPDATE_DEFAULTS', ['cover_page_subtitle_color', data.cover_page_subtitle_color]);
          commit('UPDATE_DEFAULTS', ['cover_page_author', data.cover_page_author]);
          commit('UPDATE_DEFAULTS', ['cover_page_author_size', data.cover_page_author_size]);
          commit('UPDATE_DEFAULTS', ['cover_page_author_color', data.cover_page_author_color]);
          callback(data);
        });
    },

    downloadPdf({ state }, [designs, callback]) {
      state.last_action = "downloadPdf";

      var params = new FormData();
      params.append("action", "call_fjtl_download_location");
      params.append("designs", designs);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    scheduleDownloadPdf({ state }, [designs, callback]) {
      state.last_action = "scheduleDownloadPdf";

      var params = new FormData();
      params.append("action", "fjtl_schedule_download");
      params.append("designs", designs);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    checkScheduleDownloadPdf({ state }, callback) {
      state.last_action = "checkScheduleDownloadPdf";

      var params = new FormData();
      params.append("action", "fjtl_check_schedule");

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

    updatePromptTitle({ state }, [prompt_id, post_title, callback]) {
      state.last_action = "updatePromptTitle";
      var params = new FormData();
      params.append("action", "call_update_user_prompt_title");
      params.append("prompt_id", prompt_id);
      params.append("post_title", post_title);

      axios
        .post("/wp-admin/admin-ajax.php", params)
        .then((response) => response.data)
        .then(({ data }) => {
          callback(data);
        });
    },

  },
});
